import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "@fontsource/fredoka-one";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCtHzDUXbrjgphS9-VPFytWSFJ5zWlTudI",
    authDomain: "sethhenry-dev.firebaseapp.com",
    projectId: "sethhenry-dev",
    storageBucket: "sethhenry-dev.appspot.com",
    messagingSenderId: "508998356009",
    appId: "1:508998356009:web:28264fd821db851b4ae038",
    measurementId: "G-JWJTCRG7C0"
};

// Initialize Firebase
initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
